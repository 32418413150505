<template>
  <section>
    <div class="home-sec" v-bind:style="landingBackground">
      <v-app-bar
        app
        fixed
        flat
        dense
        dark
        color="transparent"
        tag="header"
        class="appbar-transparent"
      >
        <v-spacer></v-spacer>
        <v-icon class="mr-1" @click.stop="install()" v-if="installable">mdi-cellphone-arrow-down-variant</v-icon>
        <v-app-bar-nav-icon aria-label="menu" @click.stop="openDrawer()"/>
      </v-app-bar>
      <div class="overlay w-100 h-100 position-absolute"></div>
      <CentralLoader v-if="isLoading || !profile" color="white"></CentralLoader>
      <div v-else class="container">
        <div class="row">
          <div class="col-sm-12 p-0">
            <div
              class="
                d-flex
                pt-5
                justify-content-center
                flex-column
                align-items-center
              "
            >
              <div
                class="text-center"
                style="cursor: pointer"
              >
                <a
                  class="text-decoration-none"
                  :href="profile.options.merchant_extenal"
                  target="_blank"
                >
                  <div class="rest-logo-cont mx-auto">
                    <img
                      :alt="profile.restaurant_name"
                      :src="getMerchantLogo()"
                      width="80" height="80"
                    />
                  </div>
                  <h2 class="text-white title m-0 font-weight-6 px-4">
                    {{ profile.restaurant_name }}
                  </h2>
                </a>
                <div @click="$router.push({ name: 'reviews' }).catch(() => {})">
                  <v-rating
                    color="primary"
                    background-color="primary"
                    empty-icon="mdi-star-outline"
                    half-icon="mdi-star-half-full"
                    half-increments
                    dense
                    readonly
                    v-model="rating"
                  ></v-rating>
                  <small class="text-white subtitle-1"
                    >{{ reviews.length }} reviews</small
                  >
                </div>
              </div>
              <div class="btn mt-2 mb-8 bg-chat text-caption blue-grey--text text--darken-1 font-weight-6" v-html="welcomeMessage()"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            key="menu"
            v-if="profile.options.merchant_photo_menubook"
            @click="selectService('Menubook', 'menubook')"
            class="col-sm-12 p-0 d-flex justify-content-center"
          >
            <div
              class="
                bg-white
                mb-3
                w-60
                rounded-8
                d-flex
                justify-content-start
                service-option
              "
            >
              <v-icon color="primary">mdi-clipboard-list-outline</v-icon>
              <p class="font-weight-6 my-auto ml-4 sm-font">Menu</p>
            </div>
          </div>
          <div
            :key="service.value"
            v-for="service in profile.service"
            @click="selectService(service.label, service.value)"
            class="col-sm-12 p-0 d-flex justify-content-center"
          >
            <div
              class="
                bg-white
                mb-3
                w-60
                rounded-8
                d-flex
                justify-content-start
                service-option
              "
            >
              <img :alt="service.label" :src="require(`@/assets/images/${service.value}.svg`)" />
              <p class="font-weight-6 my-auto ml-4 sm-font">{{ service.label }}</p>
            </div>
          </div>
          <div
            key="reservation"
            v-if="hasTableBooking"
            @click="selectService('Reservation', 'reservation')"
            class="col-sm-12 p-0 d-flex justify-content-center"
          >
            <div
              class="
                bg-white
                mb-3
                w-60
                rounded-8
                d-flex
                justify-content-start
                service-option
              "
            >
              <img alt="Reservation" :src="require(`@/assets/images/reservation.svg`)" />
              <p class="font-weight-6 my-auto ml-4 sm-font">Reservation</p>
            </div>
          </div>
          <div
            key="branch"
            v-if="hasBranch"
            @click="showBranch = true"
            class="col-sm-12 p-0 d-flex justify-content-center"
          >
            <div
              class="
                bg-white
                mb-3
                w-60
                rounded-8
                d-flex
                justify-content-start
                service-option
              "
            >
              <v-icon color="primary">mdi-store-marker</v-icon>
              <p class="font-weight-6 my-auto ml-4 sm-font">Outlets</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <notify-update-modal :version="version" v-if="version && hasUpdate"></notify-update-modal>
    <delivery-provider-bottom-sheet :visible="showDeliveryProvider" v-on:close-delivery-provider="showDeliveryProvider = false"></delivery-provider-bottom-sheet>
    <branch-modal :visible="showBranch" v-on:close-branch="showBranch = false"></branch-modal>
    <Footer></Footer>
  </section>
</template>

<script>
import NotifyUpdateModal from '@/components/DialogModals/NotifyUpdateModal';
import Footer from '@/components/partials/Footer';
import CentralLoader from '@/components/Loaders/CentralLoader';
import DeliveryProviderBottomSheet from '@/components/BottomSheets/DeliveryProviders';
import BranchModal from '@/components/Modals/Branches';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  props: ['installable'],
  components: {
    NotifyUpdateModal,
    Footer, 
    CentralLoader,
    DeliveryProviderBottomSheet,
    BranchModal,
  },
  metaInfo() {
    return {
      title: `${this.profile.restaurant_name}`,
      titleTemplate: null,
      meta: [
        { name: 'og:url', property: 'og:url', content: `https://${this.profile.restaurant_hostname}` },
        { name: 'og:site_name', property: 'og:site_name', content: this.profile.restaurant_name },
        { name: 'og:title', property: 'og:title', content: `[Menu] ${this.profile.restaurant_name}` },
        { name: 'og:description', property: 'og:description', content: `[Menu] ${this.profile.restaurant_name}` },
        { name: 'og:image', property: 'og:image', content: this.getMerchantLogo() },
        { name: 'og:type', property: 'og:type', content: 'website' },
        { name: 'og:updated_time', property: 'og:updated_time', content: Date.now() },
      ],
    }
  },
  data() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      version: null,
      isLoading: false,
      hasUpdate: false,
      title: 'EatMol',
      serviceRoutes: {
        menubook: 'menubook',
        delivery: 'menu',
        pickup: 'menu',
        dinein: 'scan',
        reservation: 'reserve',
      },
      showDeliveryProvider: false,
      showBranch: false,
      alertMessage: {
        'invalid-voucher': 'Voucher is invalid or expired!',
      }
    };
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
      reviews: (state) => state.reviews,
      storeClosed: (state) => state.storeClosed,
      ondemand: (state) => state.ondemand,
      acceptPreOrder: (state) => state.preOrder,
    }),
    ...mapGetters('merchant', {
      rating: ['getRating'],
      isTodayHoliday: ['isTodayHoliday'],
      hasTableBooking: ['hasTableBooking'],
      hasBranch: ['hasBranch'],
    }),
    landingBackground: function() {
      return this.profile.photo_landing_bg ? {
        backgroundImage: `url(${this.cdnUrl}/${this.profile.photo_landing_bg})`
      } : null;
    },
    hasLinks: function () {
      return Object.values(this.profile?.links || {})?.some(link => !!link);
    }
  },
  methods: {
    ...mapActions('config', ['getAdminOption']),
    ...mapActions('customer', ['claimVoucher']),
    ...mapActions('merchant', [
      'getMerchantByHostname', 
      'getMerchantBusinessHours',
      'getMerchantReviews'
    ]),
    ...mapMutations('cart', ['SET_SERVICE']),
    selectService(label, value) {
      this.$gtag.event('select-service', {
        'event_category': 'navigation',
        'event_label': label,
        'event_value': value,
      });

      if (value !== 'menubook') {
        this.SET_SERVICE(value);
      }

      if (value === 'delivery' && this.hasLinks) {
        this.showDeliveryProvider = true;
      } else {
        this.$router.push({name: this.serviceRoutes[value]}).catch(() => {});
      }
    },
    getMerchantLogo() {
      if (!this.profile.logo_s3 && !this.profile.logo) {
        return require(`@/assets/images/food-item-default.jpg`);
      }

      return this.profile.logo_s3 ? (`${this.cdnUrl}/${this.profile.logo_s3}`) : (`${this.legacyCdnUrl}/upload/${this.profile.logo}`);
    },
    welcomeMessage() {
      if (this.storeClosed) {
        return `${this.profile.options.merchant_close_msg || 'Sorry, we are closed now.'}`;
      } else if (this.isTodayHoliday) {
        return `${this.profile.options.merchant_close_msg_holiday || 'Sorry, we are closed today.'}`;
      } else {
        return 'Welcome, how can we help you?';
      }
    },
    install() {
      this.$emit('install', 'home');
    },
    openDrawer() {
      this.$gtag.event('open-drawer', {
        'event_category': 'interaction',
        'event_label': 'Open Drawer',
      });
      this.$emit('open-drawer');
    },
    async autoClaimVouchers() {
      const unclaimedVouchers = JSON.parse(localStorage.vouchers || '[]');
      try {
        this.isLoading = true;

        await Promise.all(unclaimedVouchers.map(v => this.claimVoucher({
          voucherId: v.id,
          merchantId: this.profile.merchant_id,
        })));
      } finally {
        localStorage.removeItem('vouchers');
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.$gtag.pageview(this.$route);

    if (this.$route.query?.alert) {
      alert(this.alertMessage[this.$route.query?.alert]);
    }

    if (localStorage.token) {
      this.autoClaimVouchers();
    }
  },
  async created() {
    try {
      this.isLoading = true;

      if (!this.profile) {
        // Get merchant profile
        await this.getMerchantByHostname(process.env.VUE_APP_DEFAULT_HOSTNAME || location.hostname);
      }
      
      // Load Business Hours
      await this.getMerchantBusinessHours({
        merchantId: this.profile.merchant_id,
        service: this.service,
        stateId: this.profile.state_id,
      });

      // Get merchant reviews
      await this.getMerchantReviews(this.profile.merchant_id);
      
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  },
};
</script>

<style scoped>
header.appbar-transparent {
  height: 0px !important;
}
.home-sec {
  position: relative;
  z-index: 99;
  min-height: 95vh;
  background-image: url('~@/assets/images/bgmain.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.rounded-8 {
  border-radius: 8px;
}
.w-60 {
  width: 60% !important;
}
.overlay {
  background: rgb(0, 0, 0, 0.35);
}
.rest-logo-cont {
  width: 80px;
  margin-bottom: 2.5px;
}
.yellow-stars-cont {
  color: yellow;
  font-size: 15px;
}
.star-icon {
  margin-right: 5px;
}
.rest-specs {
  font-size: 7px;
}
.sm-font {
  font-size: 10px;
}
.service-option {
  cursor: pointer;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: 15px;
  padding-right: 15px;
}
.service-option img, .service-option .v-icon {
  width: 20px;
  height: 20px;
}
.bg-chat {
  transform: translatey(0px);
  animation: float 5s ease-in-out infinite;
  text-align: justify;
  /* font-size: 12px !important; */
  line-height: 200%;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 11px;
  position: relative;
  box-shadow: 10px 10px rgba(255, 255, 255, 0.3);
  width: 85%;
}
.bg-chat:after {
  transform: translatey(0px);
  animation: float2 5s ease-in-out infinite;
  content: ".";
  font-weight: bold;
  -webkit-text-fill-color: #ffffff;
  text-shadow: 18px 18px rgba(255, 255, 255, 0.3);
  text-align: left;
  font-size: 55px;
  width: 55px;
  height: 11px;
  line-height: 30px;
  border-radius: 11px;
  background-color: #ffffff;
  position: absolute;
  display: block;
  bottom: -30px;
  left: 0;
  box-shadow: 18px 18px rgba(255, 255, 255, 0.3);
  z-index: -2;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float2 {
  0% {
    line-height: 30px;
    transform: translatey(0px);
  }
  55% {
    transform: translatey(-10px);
  }
  60% {
    line-height: 10px;
  }
  100% {
    line-height: 30px;
    transform: translatey(0px);
  }
}
@media screen and (min-width: 320px) {
  .service-option {
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .w-60 {
    width: 40% !important;
  }
}
@media screen and (min-width: 360px) {
  .service-option {
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .w-60 {
    width: 40% !important;
  }
  .sm-font {
    font-size: 12px;
  }
  .service-option img, .service-option .v-icon {
    width: 18px;
    height: 18px;
  }
}
@media screen and (min-width: 375px) {
  .service-option {
    padding-bottom: 14px;
    padding-top: 14px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .sm-font {
    font-size: 14px;
  }
}
@media screen and (height: 667px) {
  .service-option {
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .sm-font {
    font-size: 12px;
  }
}
@media screen and (min-width: 411px) {
  .rest-specs {
    font-size: 8.7px;
  }

  .yellow-stars-cont {
    font-size: 20px;
  }
  .service-option {
    padding-bottom: 14px;
    padding-top: 14px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .sm-font {
    font-size: 14px;
  }
}
</style>
