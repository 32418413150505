<script>
export default {
  props: ['fixed'],
};
</script>
<template>
  <div :class="`footer d-flex justify-content-center align-items-center bg-white ${fixed ? 'position-fixed' : ''}`">
    <p style="font-size: 11px;font-weight: 600">Powered by Eatmol</p>
  </div>
</template>
<style scoped>
.footer {
  /* position: relative; */
  height: 5vh;
}

.position-fixed {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 9999;
  transform: translateX(-50%);
}
</style>
